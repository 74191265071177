.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
}

.backgroundVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(44, 187, 243, 0.5);
  z-index: -1;
}

.first {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  width: 100%;
  color: #fff;
  margin-top: -140px;
  z-index: 1;
  overflow: hidden;
}

.first h1 {
  font-size: 3rem;
  max-width: 800px;
  font-weight: bold;
}

.first p {
  font-size: 1.5rem;
  margin-bottom: 30px;
  max-width: 600px;
}

.first button {
  padding: 15px 30px;
  font-size: 1.2rem;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s ease;
  background: none;
  border-width: 2px;
  border-style: solid;
}

/* @keyframes slideOutToRight {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
} */

/* .slideOut {
  animation: slideOutToRight 1s ease-out forwards;
}

.slideIn {
  animation: slideInFromLeft 1s ease-out forwards;
}

@keyframes typing {
  from { width: 0; }
  to { width: 100%; }
}

@keyframes blinkCaret {
  from, to { border-color: transparent; }
  50% { border-color: black; }
}

.typing-container {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  border-right: .15em solid black;
  font-size: 2em;
  animation: typing 3s steps(40, end), blinkCaret .75s step-end infinite;
} */

/* homePage.module.css */
@keyframes typing {
  from { width: 0; }
  to { width: 100%; }
}

@keyframes blinkCaret {
  from, to { border-color: transparent; }
  50% { border-color: black; }
}

.typing-container {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  border-right: .15em solid black;
  font-size: 2em;
  animation: typing 4s steps(40, end), blinkCaret .75s step-end infinite;
}

.typing-container span {
  display: inline-block;
  opacity: 0;
  animation: fadeIn 1s forwards;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}