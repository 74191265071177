:root {
    --main-background: #f4f4f4;
    --card-background: #fff;
    --title-main: #5B9BD5;
    --title-black: #000;
    --text-color: #666;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    width: 100%;
    height: auto;
    padding: 20px;
    box-sizing: border-box;
    background-color: var(--main-background);
}

.title h1 {
    font-size: 35px;
    color: #0c6cc5;
    max-width: 400px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.content {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 40px;
    background-color: var(--card-background);
    border: 1px solid var(--main-background);
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    transition: transform 0.2s ease-in-out;
    padding: 20px;
}

.content:hover {
    transform: scale(1.02);
}

.image img {
    flex: 1;
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    margin-right: 20px;
}

.text {
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.text h2 {
    font-size: 2rem;
    margin-bottom: 10px;
    color: var(--title-black);
}

.text span {
    max-width: 600px;
    font-size: 20px;
    line-height: 1.5;
    color: var(--text-color);
}

@media only screen and (max-width: 1200px) {
    .slider img {
        max-width: 70%;
    }

    .content {
        flex-direction: column;
    }
}

@media only screen and (max-width: 600px) {
    .slider img {
        max-width: 90%;
    }
}