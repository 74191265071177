:root {
  --main-background: #f4f4f4;
  --card-background: #fff;
  --title-main: #5B9BD5;
  --title-black: #000;
  --text-color: #666;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 50px;
  box-sizing: border-box;
  background-color: var(--main-background);
}

.title h1 {
  font-size: 40px;
  color: #0c6cc5;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.cards {
  display: flex;
  justify-content: center;
  gap: 50px;
  flex-wrap: wrap;
  padding: 50px;
  background-color: var(--card-background);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  border-radius: 55px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.card {
  border: 1px solid var(--title-black);
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  transition: transform 0.2s ease-in-out;
  width: 200px;
  padding: 1px;
}

.card:hover {
  transform: scale(1.05);
}

.card img {
  display: block;
  max-width: 100%;
  height: auto;
}

@media only screen and (max-width: 1080px) {
  .cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 600px) {
  .cards {
    grid-template-columns: repeat(1, 1fr);
  }
}