.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  background-color: #2f4c55;
  box-sizing: border-box;
  padding-left: 100px;
  padding-right: 100px;
}

.footerLogo,
.footerLinks,
.office,
.contact {
  flex: 1;
  padding: 20px;
}

.footerLogo {
  display: flex;
  flex-direction: column;
  width: 20%;
}

.footerLogo img {
  max-width: 100%;
  height: auto;
  margin-bottom: 15px;
}

.footerLogos {
  display: flex;
  justify-content: space-evenly;
}

.footerLogos>img {
  max-width: 50%;
  height: auto;
  object-fit: contain;
}

.footerLinks,
.office,
.contact {
  width: 20%;
}

.footerLinks h2,
.office h2,
.contact h2 {
  color: #ffffff;
  font-size: 18px;
  font-weight: 900;
  margin-bottom: 20px;
}

.menuLink {
  margin-bottom: 10px;
}

.menuLink a {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  font-size: 16px;
}

.menuLink a:hover {
  color: #ffffff;
}

.officeTitle {
  color: #ffffff;
  margin-bottom: 15px;
}

.officeTitle img {
  margin-right: 10px;
}

.officeTitle a {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  font-size: 16px;
}

.officeTitle a:hover {
  color: #ffffff;
}

.contact img {
  margin-right: 10px;
}

.contact a {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  font-size: 16px;
}

.contact a:hover {
  color: #ffffff;
}

.contactPhone {
  margin-bottom: 20px;
}

.footerMedia {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.footerMedia img {
  width: 30px;
  height: 30px;
}

@media (max-width: 1000px) {
  .container {
    flex-direction: column;
  }

  .footerLogo,
  .footerLinks,
  .office,
  .contact {
    width: 100%;
    padding: 10px;
  }
}