:root {
  --main-background: #f4f4f4;
  --card-background: #fff;
  --title-main: #5B9BD5;
  --title-black: #000;
  --text-color: #666;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  width: 100%;
  height: auto;
  padding: 50px;
  box-sizing: border-box;
  background-color: var(--main-background);
}

.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.title h3 {
  font-size: 30px;
  color: #0c6cc5;
  max-width: 400px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.title p {
  font-size: 1.2rem;
  max-width: 600px;
  line-height: 1.6;
  color: var(--title-black);
}

.body {
  padding: 20px;
}

.cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--card-background);
  border: 1px solid var(--main-background);
  border-radius: 15px;
  padding: 20px;
  padding-bottom: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  gap: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: auto;
}

.card img {
  width: 30px;
  height: 30px;
}

.card p {
  font-size: 16px;
  color: var(--title-black);
  font-weight: bold;
  margin: 0;
  margin-top: 20px;
  margin-bottom: 10px;
}

.card span {
  font-size: 14px;
  color: var(--text-color);
  text-align: center;
}

.card:hover {
  transform: translateY(-5px) scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  background-color: var(--title-main);
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
}

.card:hover p {
  color: var(--card-background);
}

.card:hover span {
  color: var(--card-background);
}

@media only screen and (max-width: 1080px) {
  .cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 900px) {
  .title {
    flex-direction: column;
  }

  .cards {
    grid-template-columns: repeat(1, 1fr);
  }
}