:root {
  --main-background: #f4f4f4;
  --card-background: #fff;
  --title-main: #5B9BD5;
  --title-black: #000;
  --text-color: #666;
}

.containerActive {
  display: flex;
  position: sticky;
  top: 0;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 110px;
  z-index: 2;
  background-color: var(--card-background);
  color: var(--title-black);
}

.container {
  display: flex;
  position: sticky;
  top: 0;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 130px;
  z-index: 2;
  background: transparent;
  color: var(--card-background);
}

.logoLabel {
  flex: 1;
}

.logoLabel img {
  max-width: 40%;
  height: auto;
  border-radius: 8px;
  margin-top: 30px;
}

.container>.logoLabel img {
  max-width: 50%;
  height: auto;
  border-radius: 8px;
  margin-top: 40px;
}

.menuLinks {
  flex: 2;
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  margin-right: 20px;
  gap: 40px;
}

.menuLinkActive a {
  color: var(--title-black);
  font-size: 16px;
  font-style: bold;
  font-weight: 700;
  line-height: normal;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.5s;
}

.menuLinkActive :hover {
  color: var(--title-main);
}

.menuLink a {
  color: white;
  font-size: 16px;
  font-style: bold;
  font-weight: 700;
  line-height: normal;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.5s;
}

.menuLink p {
  color: rgb(0, 0, 0);
}

.menuLink :hover {
  color: var(--title-main);
}

.mobileMenuIcon {
  display: none;
}

.mobileMenu {
  display: none;
}

.mobileMenuIcon>img {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.mobileMenuLinks {
  margin-bottom: 20px;
}

.mobileMenuLinks>a>span {
  color: var(--title-black);
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdownToggle {
  padding: 10px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
}

.dropdownMenu {
  display: block;
  position: absolute;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  list-style-type: none;
  margin: 0;
  padding: 0;
  border-radius: 4px;
  overflow: hidden;
}

.dropdownMenu li {
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdownMenu li:hover {
  background-color: #f1f1f1;
}

.dropdown img {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-start;
  z-index: 1000;
}

.modalContent {
  background-color: #5B9BD5;
  width: 250px;
  height: 100%;
  padding-left: 30px;
  padding-top: 10px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
}

.modalLink {
  margin-bottom: 5px;
  text-align: left;
}

.modalLinks {
  margin-bottom: 5px;
  text-align: left;
}

.modalLink span {
  display: block;
  font-weight: bold;
  color: #000;
}

.modalLinks span {
  display: block;
  font-weight: bold;
  color: #000;
}

.modalLink a {
  display: block;
  padding: 5px 0;
  text-decoration: none;
  font-weight: bold;
  color: #000000;
}

.modalLinks a {
  display: block;
  padding: 5px 0;
  margin-left: 20px;
  text-decoration: none;
  font-weight: bold;
  color: #333;
}

.modal .dropdown {
  display: flex;
  justify-content: start;
}

.modal .dropdownMenu {
  display: flex;
  flex-direction: row;
  position: absolute;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  list-style-type: none;
  margin: 0;
  padding: 0;
  border-radius: 4px;
  overflow: hidden;
  top: -100%;
  left: 0;
}


@media only screen and (max-width: 1080px) {

  .logoLabel img {
    max-width: 25%;
    height: auto;
  }

  .menuLinks {
    display: none;
  }

  .mobileMenuIcon {
    display: block;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 3;
  }

  .mobileMenu {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0px;
    background: transparent;
    transition: all 0.5s;
    overflow: hidden;
    z-index: 2;
    padding-top: 140px;
  }
}

@media only screen and (max-width: 900px) {
  .logoLabel img {
    max-width: 30%;
    height: auto;
  }

  .container>.logoLabel img {
    max-width: 50%;
    height: auto;
  }
}

@media only screen and (max-width: 650px) {
  .logoLabel img {
    max-width: 40%;
    height: auto;
  }

  .container>.logoLabel img {
    max-width: 60%;
    height: auto;
  }
}





