.menuLink>span{
    position: relative;
    display: inline-block;
    font-size: 16px;
    font-style: bold;
    font-weight: 700;
    line-height: normal;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.5s;
}

.dropMenu {
    display: none;
    position: absolute;
    background-color: #ffffff;
    min-width: 250px;
    border-radius: 20px;
}

.menuLink:hover .dropMenu {
    display: block;
}

.dropMenu p {
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    padding: 10px;
    margin: 10px;
    margin-top: 20px;
    border-width: 2px;
    border-style: solid;
    border-radius: 20px;
}

.dropMenu span:hover {
    background-color: #c9bfbf;
}