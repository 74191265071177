:root {
    --main-background: #f4f4f4;
    --card-background: #fff;
    --title-main: #0c6cc5;
    --title-black: #000;
    --text-color: #666;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    width: 100%;
    height: auto;
    padding: 50px;
    box-sizing: border-box;
    background-color: var(--main-background);
}

.title h1 {
    font-size: 35px;
    color: #0c6cc5;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
}

.card {
    display: flex;
    flex-direction: column;
    width: 350px;
    height: auto;
    align-items: center;
    background: var(--card-background);
    border: 1px solid var(--main-background);
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-top: 50px;
}

.card h1 {
    font-size: 25px;
    color: var(--title-black);
    font-weight: 500;
    margin: 0;
    margin-top: 20px;
    margin-bottom: 10px;
}

.card span {
    font-size: 14px;
    color: var(--text-color);
    text-align: center;
    margin-bottom: 50px;
}

.card:hover {
    transform: translateY(-5px) scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    background-color: var(--title-main);
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
}

.card:hover h1 {
    color: var(--card-background);
}

.card:hover span {
    color: var(--card-background);
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    opacity: 0;
    animation: fadeIn 0.3s forwards;
}

.modalContent {
    width: 800px;
    height: 500px;
    background-color: var(--card-background);
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
    transform: translateX(-100%);
    animation: slideIn 0.5s forwards;
    overflow: hidden;
}

.modalCont {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.modalImage {
    flex: 2;
}

.modalImage img {
    width: 90%;
    height: auto;
}

.modalText {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.modalInfo {
    flex: 1;
    margin-bottom: 10px;
}

.modalDetails {
    width: 300px;
    flex: 5;
    overflow-wrap: break-word;
    text-align: left;
    margin-bottom: 10px;
}

.modalText h1 {
    font-size: 1.5em;
    margin: 0;
    color: #333;
    margin-top: 10px;
}

.modalText span {
    display: block;
    font-size: 1.2em;
    margin: 10px 0;
    color: #666;
}

.modalText .mail {
    font-size: 1em;
    color: #007bff;
    text-decoration: none;
    margin: 5px 0;
    transition: color 0.3s ease;
}

.modalText .mail:hover {
    color: #0056b3;
}

.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
    color: var(--text-color);
}

@keyframes slideIn {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}


.modal.exit .modalContent {
    animation: slideOut 0.5s forwards;
}

@keyframes slideOut {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(100%);
    }
}

@media only screen and (max-width: 1250px) {
    .cards {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 850px) {
    .cards {
        grid-template-columns: repeat(1, 1fr);
    }
    .modalCont {
        flex-direction: column;
    }
    .modalContent {
        width: 330px;
        height: 750px;
    }
}

@media only screen and (max-width: 530px) {
    .modalContent {
        width: 330px;
        height: 750px;
    }
    .modalCont {
        flex-direction: column;
    }
}

@media only screen and (max-width: 375px) {
    .modalContent {
        width: 300px;
        height: 600px;
    }
    .modalCont {
        flex-direction: column;
    }
}

@media only screen and (max-height: 700px ) {
    .modalContent {
        width: 300px;
        height: 620px;
    }
    .modalCont {
        flex-direction: column;
    }
    .modalImage img {
        width: 60%;
        height: auto;
    }
}