.slider {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.slide {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  transition: background-image 0.5s ease-in-out;
}

.slide img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  transition: transform 0.5s ease-in-out;
}

.rightArrow {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 32px;
  font-size: 30px;
  color: #fff;
  z-index: 1;
  cursor: pointer;
}

.leftArrow {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 32px;
  font-size: 30px;
  color: #fff;
  z-index: 1;
  cursor: pointer;
}

.dotsContainer {
  display: flex;
  justify-content: center;
}

.dot {
  margin: 0 3px;
  cursor: pointer;
  font-size: 20px;
}

@media (max-width: 1200px) {

  .rightArrow,
  .leftArrow {
   display: none;
  }
}